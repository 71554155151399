import { ART_STORE, ALBUMS } from '../../constants';
import {
  ViewerScriptContext,
  ArtStoreViewerScriptManager,
  CommonViewerScript,
  WixCodeApi,
} from '@wix/common-pro-gallery-worker-wrapper';
import CartFedopsLogger from '../../components/cart/cartFedops';
import ThankyouFedops from '../../components/thankyou/thankyouFedops';

const context = new ViewerScriptContext(ART_STORE.SENTRY_DSN);
const storeManager = new ArtStoreViewerScriptManager(context);
const WIDGET_ARRAY = [
  ART_STORE.GALLERY_WIDGET_ID,
  ART_STORE.CART_WIDGET_ID,
  ART_STORE.THANK_YOU_WIDGET_ID,
];
const galleryWixCodeApi = new WixCodeApi();

const createAppController = (config) => {
  const { activeWidgets, type, wixCodeApi, compId } = config;
  if (!WIDGET_ARRAY.includes(type)) {
    return { pageReady: () => {} };
  }
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  storeManager.initCartApiIfNeeded(isSSR);
  const storeApi = storeManager.getStoreApi(wixCodeApi, compId);
  const consentPolicy = storeManager.getConsentPolicy(wixCodeApi);
  storeManager.setActiveWidgetIfNeeded(activeWidgets);
  switch (type) {
    case ART_STORE.GALLERY_WIDGET_ID:
      return getGalleryWidgetController({ config, consentPolicy, storeApi });
    case ART_STORE.CART_WIDGET_ID:
      return getCartWidgetController({
        config,
        consentPolicy,
        storeApi,
        isSSR,
      });
    case ART_STORE.THANK_YOU_WIDGET_ID:
      return getThankYouWidgetController({
        config,
        consentPolicy,
        storeApi,
      });
    default:
      return { pageReady: () => {} };
  }
};

function getGalleryWidgetController({ config, consentPolicy, storeApi }) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
    },
    setProps,
    compId,
    type,
    wixCodeApi,
  } = config;
  const commonViewerScript = new CommonViewerScript(
    context,
    config,
    ART_STORE.ARTSTORE_APP_DEFINITION_ID,
    ART_STORE.GALLERY_WIDGET_ID,
    true,
  );
  const tryToReportAppLoaded = commonViewerScript.getTryToReportAppLoaded();
  return {
    pageReady: () => {
      try {
        const renderGallery = (viewPortState) => {
          let notInView = false;
          if (viewPortState && !viewPortState.in) {
            // notInView can be true only in SSR.
            // tryToReportAppLoaded for this case will be called later here in "if (commonViewerScript.isSSR())"
            notInView = true;
          }
          commonViewerScript.loadInitialBlueprint();
          commonViewerScript.loadDirectFullscreen();
          const initPromise = new Promise((resolve, reject) => {
            try {
              storeManager
                .getConnectedProviders(
                  instance,
                  styleParams, // TODO: remove
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                )
                .then((connectedProviders) => {
                  if (connectedProviders) {
                    // this is art-store
                    Promise.all([
                      commonViewerScript.getBlueprintReadyPromise(),
                      commonViewerScript.getDirectFullscreenReadyPromise(),
                    ]).then(() => {
                      resolve({
                        connectedProviders: connectedProviders,
                      });
                    });
                  } else {
                    // albums art-store
                    const skipItems = storeManager.shouldSkipItems(wixCodeApi);
                    if (skipItems) {
                      resolve({});
                    } else {
                      Promise.all([
                        commonViewerScript.getWixCodeBlueprintReadyPromise(), // for albums we must have wix-code so we wait for them with a promise
                      ]).then(() => {
                        resolve({
                          blueprint: commonViewerScript.getBlueprint(),
                        });
                      });
                    }
                  }
                });
            } catch (e) {
              reject(e);
            }
          });
          return initPromise
            .catch((e) => {
              commonViewerScript.sentryReport(e);
              console.error('Waiting for promises failed', e);
            })
            .then(({ connectedProviders, blueprint }) => {
              const cssBaseUrls = commonViewerScript.getbaseUrls();
              let initalProps = {
                ...commonViewerScript.getCommonGalleryProps(true),
                storeApi,
                onItemClicked: commonViewerScript.getOnItemClickedFunc(
                  galleryWixCodeApi,
                ),
                watermarkData: storeManager.isAlbums(styleParams)
                  ? undefined
                  : commonViewerScript.getWatermark(),
                onLinkNavigation: commonViewerScript.getOnLinkNavigationFunc(),
                cssBaseUrl:
                  cssBaseUrls.santaWrapperBaseUrl || cssBaseUrls.staticsBaseUrl,
                styleParams: config.config.style.styleParams,
                notInView,
                totalItemsCount: commonViewerScript.getTotalItemsCount(),
                ...blueprint,
                directFullscreenItem: commonViewerScript.getDirectFullscreenItem(),
                directFullscreenMockBlueprint: commonViewerScript.getDirectFullscreenMockBlueprint(),
              };
              if (connectedProviders) {
                initalProps = {
                  ...initalProps,
                  ...storeManager.getInitalProps(
                    { compId, setProps, type, consentPolicy },
                    connectedProviders,
                    ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                  ),
                };
              }
              setProps(initalProps);
              if (commonViewerScript.isSSR()) {
                tryToReportAppLoaded(true);
              }
              return commonViewerScript.getWarmupData();
            });
        };

        return commonViewerScript
          .getViewPortPromise()
          .then(renderGallery)
          .catch((error) => {
            console.error(error);
            commonViewerScript.sentryReport({
              errorMessage: 'viewportPromise rejected!',
              error,
            });
            tryToReportAppLoaded();
          });
      } catch (e) {
        console.error(e);
        commonViewerScript.sentryReport(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateApi({
          proGalleryStore: commonViewerScript.getPgStore(),
          setNewStyleParams: (sp) =>
            commonViewerScript.handleNewStyleParams(sp),
          setClickedIdx: (clickedIdx) =>
            commonViewerScript.setClickedIdx(clickedIdx),
          setNewSettings: (settings) => {
            const { watermark, galleryId } = settings;
            const store = commonViewerScript.getPgStore();
            watermark && setProps({ watermarkWixCode: watermark });
            galleryId && (store.galleryId = galleryId);
            store.setSettings(settings);
            if (!commonViewerScript.isSSR()) {
              storeManager
                .initStoreWixCodeApi(
                  settings,
                  styleParams,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                )
                .then(({ connectedProviders, additionalProviderParams }) => {
                  additionalProviderParams &&
                    (store.freeArtStore =
                      additionalProviderParams.freeArtStore);
                  setProps(
                    storeManager.getInitalProps(
                      { compId, setProps, type, consentPolicy },
                      connectedProviders,
                      ALBUMS.ALBUMS_APP_DEF_ID,
                      additionalProviderParams,
                    ),
                  );
                });
            }
          },
        });
      } catch (e) {
        commonViewerScript.sentryReport(e);
      }
    },
  };
}

function getCartWidgetController({ config, consentPolicy, storeApi, isSSR }) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
      publicData,
    },
    setProps,
    compId,
    type,
  } = config;
  if (!isSSR && !storeManager.isAlbums(styleParams)) {
    storeManager.initTranslations(setProps);
  }
  const fedopsLogger = new CartFedopsLogger(context);
  fedopsLogger.reportAppLoadStarted();
  return {
    pageReady: () => {
      try {
        storeManager
          .getConnectedProviders(
            instance,
            styleParams, // TODO: remove
            ART_STORE.ARTSTORE_APP_DEFINITION_ID,
          )
          .then((connectedProviders) => {
            let initalProps = {
              styleParams,
              publicData,
              storeApi,
              consentPolicy,
              setActiveStoreWidget: () =>
                storeManager.changeActiveStoreWidget({
                  compId,
                  setProps,
                  type,
                }),
              tryToReportAppLoaded: () => fedopsLogger.tryReportAppLoaded(),
            };
            if (connectedProviders) {
              initalProps = {
                ...initalProps,
                ...storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                ),
              };
            }
            setProps(initalProps);
          });
      } catch (e) {
        console.error(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateCartApi(({ settings }) => {
          storeManager
            .initStoreWixCodeApi(
              settings,
              styleParams,
              ALBUMS.ALBUMS_APP_DEF_ID,
            )
            .then(({ connectedProviders, additionalProviderParams }) => {
              setProps(
                storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                  additionalProviderParams,
                ),
              );
            });
        });
      } catch (e) {}
    },
  };
}

function getThankYouWidgetController({ config, consentPolicy, storeApi }) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
    },
    setProps,
    compId,
    type,
    wixCodeApi,
  } = config;
  const thankyouPageLogger = new ThankyouFedops(context);
  thankyouPageLogger.reportAppLoadStarted();
  const queryParams = wixCodeApi.location.query;
  return {
    pageReady: () => {
      try {
        storeManager
          .getConnectedProvidersForThankYouPage(
            instance,
            styleParams, // TODO: remove
            ART_STORE.ARTSTORE_APP_DEFINITION_ID,
            wixCodeApi,
          )
          .then((connectedProviders) => {
            let initalProps = {
              styleParams,
              storeApi,
              queryParams,
              consentPolicy,
              tryToReportAppLoaded: () =>
                thankyouPageLogger.tryReportAppLoaded(),
            };
            if (connectedProviders) {
              initalProps = {
                ...initalProps,
                ...storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                ),
              };
            }
            setProps(initalProps);
          });
      } catch (e) {
        console.error(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateCartApi(({ settings }) => {
          storeManager
            .initStoreWixCodeApi(
              settings,
              styleParams,
              ALBUMS.ALBUMS_APP_DEF_ID,
            )
            .then(({ connectedProviders, additionalProviderParams }) => {
              setProps(
                storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                  additionalProviderParams,
                ),
              );
            });
        });
      } catch (e) {}
    },
  };
}
export const wrappedFunctions = {
  initAppForPage: CommonViewerScript.getInitAppForPageFunc(context),
  createControllers: CommonViewerScript.getCreateControllers(
    createAppController,
    context.getSentry(),
  ),
};
